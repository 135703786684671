class DateRangeSelector {
    #startDate: Date | null = null;

    #endDate: Date | null = null;

    constructor(startDate: Date | null = null, endDate: Date | null = null) {
        this.#startDate = startDate;
        this.#endDate = endDate;
    }

    selectDate(date: Date): void {
        if (this.#endDate !== null) {
            this.#startDate = new Date(date);
            this.#endDate = null;
            return;
        }

        if (this.#startDate !== null && date.getTime() < this.#startDate.getTime()) {
            this.#startDate = new Date(date);
            return;
        }

        if (this.#startDate !== null) {
            this.#endDate = new Date(date);
            return;
        }

        this.#startDate = new Date(date);
    }

    set startDate(date: Date | null) {
        this.#startDate = date;
    }

    set endDate(date: Date | null) {
        this.#endDate = date;
    }

    get range(): DateRange {
        return {
            start: this.#startDate !== null ? new Date(this.#startDate) : null,
            end: this.#endDate !== null ? new Date(this.#endDate) : null
        };
    }

    clear(): void {
        this.#startDate = null;
        this.#endDate = null;
    }
}

export default DateRangeSelector;
